<template>
  <div style="height: 500px">
    <div style="text-align: center;line-height: 500px">
      <el-date-picker
          v-model="weeknum"
          type="week"
          :format='weekstart + "~" + weekend + "  " + "[第] ww [周]"'
          placeholder="请选择日期"
          @change="getxxday(weeknum)"
          style="width: 300px;margin-right: 20px"
      >
      </el-date-picker>
      <el-button type="danger" @click="goweekplan">查看周工作计划汇总</el-button>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "poolplan",
  data(){
    return{
      weeknum: '',
      weekstart: '',
      weekend: '',
    }
  },
  created() {

  },
  methods:{
    getxxday(val) {
      this.weekstart = dayjs(val).startOf('week').format('YYYY-MM-DD');
      this.weekend = dayjs(val).endOf('week').format('YYYY-MM-DD');
      this.relweeknum = dayjs(val).week();
      console.log(this.weekstart);
      console.log(this.weekend)
    },
    goweekplan(){
      this.$router.push({
        path: '/planaudit',
        name: 'Planaudit',
        params: {
          weeknum: this.weeknum,
          weekstart: this.weekstart,
          weekend: this.weekend

        }
      })
    }
  }
}
</script>

<style scoped>

</style>
