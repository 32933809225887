<template>
  <div style="background-color: #F2F2F2">
<!--    固定模板-->
    <Head></Head>
    <div id="admindiv1">
      <div style="margin-top: 15px;font-family: '苹方',serif;font-size: 13px;margin-left: 10px">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 13px" :to="{ path: '/manage' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 13px">周工作计划</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!--      页面具体内容-->
    <div id="weekplan">
      <el-tabs v-model="activeName" tab-position="left" @tab-click="handleClick">
        <el-tab-pane label="周工作计划" name="a">
          <Thisweek></Thisweek>
        </el-tab-pane>
        <el-tab-pane v-if="this.GET_DEPART === '综合管理部'" label="周工作计划汇总" name="b">
          <Poolweek></Poolweek>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Head from "@/views/header";
import Thisweek from "@/views/imd/weekplan/thisweek";
import Poolweek from "@/views/imd/weekplan/poolplan";
import {mapGetters} from "vuex";
export default {
  components: {Head, Thisweek, Poolweek},
  name: "weekplan",
  data(){
    return{
      activeName: 'a',
    }
  },
  computed: {
    ...mapGetters(['GET_DEPART']),
  },
  created() {
    if (!this.$route.params.key) {
      this.$router.push("/manage")
    }
  },
  methods:{
    handleClick(tab, event) {
      //console.log(tab)
      // 点击tab后触发事件，修改显示页面，将状态保存在sessionStorage里面
      //sessionStorage.setItem('currentTab', tab.props.name)
    },
  }
}
</script>

<style scoped>
#admindiv1 {
  background-color: white;
  display: flex;
  width: 90%;
  height: 40px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  justify-content: space-between;
}
#weekplan{
  border-radius: 5px;
  margin: 0 auto;
  background-color: white;
  width: 90%;
  min-height: 500px;
  padding-top: 10px;
}
</style>
