<template>
  <div>
    <!--    搜索之前选择  -->
    <div v-if="initplan" style="display: flex;margin: 100px auto 0 200px">
      <div class="xzdiv">
        <img :src="mythisweek" alt="" style="width: 60px;height: 60px;margin-top: 20px"/>
        <p class="font1">
          我的
        </p>
        <p class="font2">
          查看我的周计划
        </p>
        <div>
          <el-button type="primary" plain @click="showmy">查看周计划</el-button>
        </div>
      </div>
      <div class="xzdiv">
        <img :src="departthisweek" alt="" style="width: 60px;height: 60px;margin-top: 20px"/>
        <p class="font1">
          本部门
        </p>
        <p class="font2">
          查看本部门周计划
        </p>
        <div>
          <el-button type="warning" plain @click="showdepart">查看周计划</el-button>
        </div>
      </div>
      <div class="xzdiv">
        <img :src="jzthisweek" alt="" style="width: 60px;height: 60px;margin-top: 20px"/>
        <p class="font1">
          项目公司
        </p>
        <p class="font2">
          查看项目公司周计划
        </p>
        <div>
          <el-button style="margin-bottom: 10px" s type="danger" plain @click="showjz">查看周计划</el-button>
        </div>
      </div>
    </div>
    <!--    自己本周计划-->
    <div v-if="myplan">
      <div style="display: flex;justify-content: space-between">
        <div>
          <el-date-picker
              v-model="weeknum"
              type="week"
              :format='weekstart + "~" + weekend + "  " + "[第] ww [周]"'
              placeholder="请选择日期"
              size="small"
              @change="getxxday(weeknum)"
              style="width: 260px;"
              @blur="gethisweek"
          >
          </el-date-picker>
        </div>
        <div>
          <el-tag v-if="this.nextweeknum >= this.relweeknum" style="margin-right: 20px"> 本周第 {{ nextweeknum }} 周
          </el-tag>
          <el-button v-if="this.nextweeknum < this.relweeknum" size="mini" type="warning" style="margin-right: 20px"
                     @click="addplan">新增工作计划
          </el-button>
        </div>
        <div style="margin-right: 30px">
          <el-button type="primary" plain size="small" @click="backinit">返回</el-button>
        </div>
      </div>
      <div style="margin: 20px auto 0 auto">
        <vxe-table
            border="full"
            :align="allAlign"
            :data="thisweekData"
            size="small"
            ref="xTable1"
        >
          <vxe-column field="message" width="560" title="工作目标及内容" show-header-overflow
                      show-overflow="title"></vxe-column>
          <vxe-column field="mainpeo" width="160" title="负责人" show-header-overflow show-overflow="title"></vxe-column>
          <vxe-column field="phdepart" width="160" title="配合部门(人员)" show-header-overflow
                      show-overflow="title"></vxe-column>
          <vxe-column field="timend" width="180" title="时间节点" show-header-overflow show-overflow="title"></vxe-column>
          <vxe-column v-if="this.nextweeknum === this.relweeknum" field="isend" title="是否完成">
            <template #default="{ row }">
              <el-tag v-if="row.isend === 0" type="danger" size="small" @click="updataisend(row)">未完成</el-tag>
              <el-tag v-if="row.isend !== 0" type="success" size="small" @click="updataisend(row)">已完成</el-tag>
            </template>
          </vxe-column>
          <vxe-column v-if="this.nextweeknum === this.relweeknum" field="isend" title="操作">
            <template #default="{ row }">
              <el-button plain type="danger" size="mini" @click="editplan(row)">修改</el-button>
            </template>
          </vxe-column>
          <vxe-column v-if="this.nextweeknum < this.relweeknum" field="audit" title="操作">
            <template #default="{ row }">
              <el-button plain type="danger" size="mini" @click="editplan(row)">修改</el-button>
            </template>
          </vxe-column>
        </vxe-table>
      </div>
    </div>

    <!--    部门本周计划-->
    <div v-if="departplan">
      <div style="display: flex;justify-content: space-between">
        <div>
          <el-date-picker
              v-model="weeknum"
              type="week"
              :format='weekstart + "~" + weekend + "  " + "[第] ww [周]"'
              placeholder="请选择日期"
              size="small"
              @change="getxxday(weeknum)"
              style="width: 260px;"
              @blur="getdepartthisweek"
          >
          </el-date-picker>
        </div>
        <div style="margin-right: 30px">
          <el-button type="primary" plain size="small" @click="backinit">返回</el-button>
        </div>
      </div>
      <div style="margin: 20px auto 0 auto">
        <vxe-table
            border="full"
            :align="allAlign"
            :data="departthisweekData"
            size="small"
            ref="xTable1"
        >
          <vxe-column field="message" width="560" title="工作目标及内容" show-header-overflow
                      show-overflow="title"></vxe-column>
          <vxe-column field="mainpeo" width="100" title="负责人" show-header-overflow show-overflow="title"></vxe-column>
          <vxe-column field="phdepart" width="160" title="配合部门(人员)" show-header-overflow
                      show-overflow="title"></vxe-column>
          <vxe-column field="adduser" width="80" title="填报人" show-header-overflow
                      show-overflow="title"></vxe-column>
          <vxe-column field="timend" width="180" title="时间节点" show-header-overflow show-overflow="title"></vxe-column>
          <vxe-column field="isend" title="是否完成">
            <template #default="{ row }">
              <el-tag v-if="row.isend === 0" type="danger" size="small">未完成</el-tag>
              <el-tag v-if="row.isend !== 0" type="success" size="small">已完成</el-tag>
            </template>
          </vxe-column>
        </vxe-table>
      </div>
    </div>

    <!--    局指本周计划-->
    <div v-if="jzplan">
      <div style="display: flex;justify-content: space-between">
        <div>
          <el-date-picker
              v-model="weeknum"
              type="week"
              :format='weekstart + "~" + weekend + "  " + "[第] ww [周]"'
              placeholder="请选择日期"
              size="small"
              @change="getxxday(weeknum)"
              style="width: 260px;"
              @blur="getjzthisweek"
          >
          </el-date-picker>
        </div>
        <div style="margin-right: 30px">
          <el-button type="primary" plain size="small" @click="backinit">返回</el-button>
        </div>
      </div>
      <div style="margin: 20px auto 0 auto">
        <vxe-table
            border="full"
            :align="allAlign"
            :data="jzthisweekData"
            size="small"
            ref="xTable1"
            :span-method="mergeRowMethod"
        >
          <vxe-column field="addpepart" width="130" title="科室" show-header-overflow
                      show-overflow="title"></vxe-column>
          <vxe-column field="message" width="560" title="工作目标及内容" show-header-overflow
                      show-overflow="title"></vxe-column>
          <vxe-column field="mainpeo" width="90" title="负责人" show-header-overflow show-overflow="title"></vxe-column>
          <vxe-column field="phdepart" width="150" title="配合部门(人员)" show-header-overflow
                      show-overflow="title"></vxe-column>
          <vxe-column field="timend" width="160" title="时间节点" show-header-overflow show-overflow="title"></vxe-column>
          <vxe-column field="isend" title="是否完成">
            <template #default="{ row }">
              <el-tag v-if="row.isend === 0" type="danger" size="small">未完成</el-tag>
              <el-tag v-if="row.isend !== 0" type="success" size="small">已完成</el-tag>
            </template>
          </vxe-column>
        </vxe-table>
      </div>
    </div>
    <!--添加周工作计划-->
    <div>
      <el-dialog
          v-model="dialogVisible"
          title="新增周工作计划"
          width="80%"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
      >
        <div style="display: flex;margin-bottom: 20px">
          <el-tag type="info" style="margin-right: 10px">工作目标及内容</el-tag>
          <el-input v-model="workmes" placeholder="工作目标及内容" size="small"/>
        </div>
        <div style="display: flex;margin-bottom: 20px">
          <el-tag type="info" style="margin-right: 10px">&#12288;负&#12288;责&#12288;人&#12288;</el-tag>
          <el-select style="margin-left: 5px" v-model="mainpeosel" placeholder="请选择负责人" size="small" @change="operid">
            <el-option
                v-for="item in mainpeos"
                :key="item.name"
                :label="item.name"
                :value="item.name"
            >
            </el-option>
          </el-select>
        </div>
        <div style="display: flex;margin-bottom: 20px">
          <el-tag type="info" style="margin-right: 13px">配合部门(人员)</el-tag>
          <el-select style="margin-left: 5px" v-model="phdepart" placeholder="请选择配合部门" size="small" @change="operid">
            <el-option
                v-for="item in phdeparts"
                :key="item.departmentname"
                :label="item.departmentname"
                :value="item.departmentname"
            >
            </el-option>
          </el-select>
          <el-input v-model="phpeo" placeholder="请输入配合人员" size="small" style="width: 200px;margin-left: 20px"/>
        </div>
        <div style="display: flex;margin-bottom: 20px">
          <el-tag type="info" style="margin-right: 10px">时&#12288;间&#12288;节&#12288;点</el-tag>
          <el-date-picker
              style="width: 200px"
              size="small"
              v-model="endtime"
              type="date"
              placeholder="时间节点"
              format="YYYY/MM/DD"
              value-format="YYYY-MM-DD"
          >
          </el-date-picker>
        </div>
        <div style="display: flex;margin-bottom: 20px">
          <el-tag type="info" style="margin-right: 10px">&#12288;&#12288;备&#12288;注&#12288;&#12288;</el-tag>
          <el-input v-model="beizhu" placeholder="请输入备注" size="small"/>
        </div>
        <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="addworkplan">提交</el-button>
      </span>
        </template>
      </el-dialog>
    </div>
<!--    修改工作计划-->
    <div>
      <el-dialog
          v-model="dialogVisible1"
          title="修改周工作计划"
          width="80%"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
      >
        <div style="display: flex;margin-bottom: 20px">
          <el-tag type="info" style="margin-right: 10px">工作目标及内容</el-tag>
          <el-input v-if="editmes === '0'" v-model="workmes" placeholder="工作目标及内容" size="small"/>
          <el-input v-if="editmes === '1'" disabled v-model="workmes" placeholder="工作目标及内容" size="small"/>
        </div>
        <div style="display: flex;margin-bottom: 20px">
          <el-tag type="info" style="margin-right: 10px">&#12288;负&#12288;责&#12288;人&#12288;</el-tag>
          <el-select v-if="editmes === '0'" style="margin-left: 5px" v-model="mainpeosel" placeholder="请选择负责人" size="small" @change="operid">
            <el-option
                v-for="item in mainpeos"
                :key="item.name"
                :label="item.name"
                :value="item.name"
            >
            </el-option>
          </el-select>
          <el-select v-if="editmes === '1'" disabled style="margin-left: 5px" v-model="mainpeosel" placeholder="请选择负责人" size="small" @change="operid">
            <el-option
                v-for="item in mainpeos"
                :key="item.name"
                :label="item.name"
                :value="item.name"
            >
            </el-option>
          </el-select>
        </div>
        <div style="display: flex;margin-bottom: 20px">
          <el-tag type="info" style="margin-right: 10px">配合部门(人员)</el-tag>
          <el-input v-if="editmes === '0'" v-model="phpeoedit" placeholder="请输入配合部门(人员)" size="small" style="width: 200px;margin-left: 20px"/>
          <el-input v-if="editmes === '1'" disabled v-model="phpeoedit" placeholder="请输入配合部门(人员)" size="small" style="width: 200px;margin-left: 20px"/>
        </div>
        <div style="display: flex;margin-bottom: 20px">
          <el-tag type="info" style="margin-right: 10px">时&#12288;间&#12288;节&#12288;点</el-tag>
          <el-date-picker
              v-if="editmes === '0'"
              style="width: 200px"
              size="small"
              v-model="endtime"
              type="date"
              placeholder="时间节点"
              format="YYYY/MM/DD"
              value-format="YYYY-MM-DD"
          >
          </el-date-picker>
          <el-date-picker
              v-if="editmes === '1'"
              disabled=""
              style="width: 200px"
              size="small"
              v-model="endtime"
              type="date"
              placeholder="时间节点"
              format="YYYY/MM/DD"
              value-format="YYYY-MM-DD"
          >
          </el-date-picker>
        </div>
        <div style="display: flex;">
          <el-tag type="info" style="margin-right: 10px">&#12288;&#12288;备&#12288;注&#12288;&#12288;</el-tag>
          <el-input v-model="beizhu" placeholder="请输入备注" size="small"/>
        </div>
        <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose1">取消</el-button>
        <el-button type="primary" @click="editworkplan">提交</el-button>
      </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {getDateString} from "@/utils/time";
import dayjs from "dayjs";
import {ElMessage} from 'element-plus'

export default {
  inject: ['reload'],
  name: "thisweek",
  data() {
    return {
      weeknum: '',
      weekstart: '',
      weekend: '',
      relweeknum: '',
      thisweekData: [],
      departthisweekData: [],
      jzthisweekData: [],
      allAlign: 'center',
      initplan: true,
      myplan: false,
      departplan: false,
      jzplan: false,
      mythisweek: 'https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/mythisweek.png',
      departthisweek: 'https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/departthisweek.png',
      jzthisweek: 'https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/jzthisweek.png',
      sevenDays: '',
      nextweeknum: '',
      dialogVisible: false,
      dialogVisible1: false,
      workmes: '',
      endtime: '',
      mainpeos: [],
      mainpeosel: '',
      phdeparts: [],
      phdepart: '',
      phpeo: '',
      phpeoedit: '',
      editid: '',
      beizhu: '',
      editmes: 0
    }
  },
  computed: {
    ...mapGetters(['GET_USER']),
    ...mapGetters(['GET_USERID']),
    ...mapGetters(['GET_DEPART']),
    ...mapGetters(['GET_POSION']),
    ...mapGetters(['GET_BDST']),
    ...mapGetters(['GET_DEPARTFLAG']),
  },
  created() {

  },
  methods: {
    showmy() {
      this.initplan = false;
      this.myplan = true;
      this.weeknum = dayjs().startOf('week').$d;
      this.nextweeknum = dayjs(this.weeknum).week();
      this.getxxday(this.weeknum);
      this.gethisweek();
    },
    showdepart() {
      this.initplan = false;
      this.departplan = true;
      this.weeknum = dayjs().startOf('week').$d;
      this.getxxday(this.weeknum);
      this.getdepartthisweek();
    },
    showjz() {
      this.initplan = false;
      this.jzplan = true;
      this.weeknum = dayjs().startOf('week').$d;
      this.getxxday(this.weeknum);
      this.getjzthisweek();
    },
    backinit() {
      this.initplan = true;
      this.myplan = false;
      this.departplan = false;
      this.jzplan = false;
      this.weeknum = '';
      this.thisweekData = []
    },
    getxxday(val) {
      this.weekstart = dayjs(val).startOf('week').format('YYYY-MM-DD');
      this.weekend = dayjs(val).endOf('week').format('YYYY-MM-DD');
      this.relweeknum = dayjs(val).week();
      this.thisweekData = [];
      this.departthisweekData = [];
      this.jzthisweekData = [];
    },
    test() {
      console.log()
    },
    gethisweek() {
      if (this.weeknum === '') {
        ElMessage.error('请选择要查看的日期')
      } else {
        this.getxxday(this.weeknum);
        this.axios.post('/weekplan/uthisweek', (response) => {
          this.thisweekData = response.obj;
          console.log(this.thisweekData)
        }, {
          userid: this.GET_USERID,
          weeknum: this.relweeknum
        })
      }
    },
    updataisend(row) {
      this.axios.post('/weekplan/updataisend', (response) => {
        if (response.obj) {
          if (row.isend === 0) {
            row.isend = 1
          } else {
            row.isend = 0
          }
        }
      }, {
        id: row.id * 1,
        isend: row.isend * 1
      })
    },
    //  查看本部门周工作计划
    getdepartthisweek() {
      console.log("本部门")
      if (this.weeknum === '') {
        ElMessage.error('请选择要查看的日期')
      } else {
        this.getxxday(this.weeknum);
        this.axios.post('/weekplan/getthisdepplan', (response) => {
          this.departthisweekData = response.obj
        }, {
          departname: this.GET_DEPART,
          weeknum: this.relweeknum
        })
      }
    },
    //  jzthisweekplan 查看局指本周工作计划
    getjzthisweek() {
      if (this.weeknum === '') {
        ElMessage.error('请选择要查看的日期')
      } else {
        this.getxxday(this.weeknum);
        this.axios.post('/weekplan/jzthisweekplan', (response) => {
          this.jzthisweekData = response.obj;
        }, {
          weeknum: this.relweeknum
        })
      }
    },
    //  合并单元格
    mergeRowMethod({row, _rowIndex, column, visibleData}) {
      const fields = ['addpepart']
      const cellValue = row[column.property]
      if (cellValue && fields.includes(column.property)) {
        const prevRow = visibleData[_rowIndex - 1]
        let nextRow = visibleData[_rowIndex + 1]
        if (prevRow && prevRow[column.property] === cellValue) {
          return {rowspan: 0, colspan: 0}
        } else {
          let countRowspan = 1
          while (nextRow && nextRow[column.property] === cellValue) {
            nextRow = visibleData[++countRowspan + _rowIndex]
          }
          if (countRowspan > 1) {
            return {rowspan: countRowspan, colspan: 1}
          }
        }
      }
    },
    addplan() {
      this.dialogVisible = true;
      this.endtime = this.weekend;
      this.axios.post("/userinfo/getleader", (response) => {
        this.mainpeos = response.obj
      }, {
        depart: this.GET_DEPART
      });
      this.axios.get("/userinfo/getdepart", (response) => {
        this.phdeparts = response.obj
      }, {
        type: 1
      })
    },
    handleClose() {
      this.dialogVisible = false;
      this.workmes = '';
      this.mainpeo = '';
      this.mainpeosel = '';
      this.phdepart = '';
      this.endtime = '';
      this.phpeo = ''
    },
    handleClose1() {
      this.dialogVisible1 = false;
      this.workmes = '';
      this.mainpeosel = '';
      this.phpeoedit = '';
      this.endtime = '';
    },
    addworkplan() {
      let phdepart = this.phdepart + this.phpeo;
      this.axios.post("/weekplan/addplan", (response) => {
        if (response.obj) {
          this.gethisweek();
          ElMessage({
            message: '提交成功',
            type: 'success',
          });
          this.workmes = '';
          this.mainpeo = '';
          this.mainpeosel = '';
          this.phdepart = '';
          this.endtime = '';
          this.phpeo = '';
          this.beizhu = ''
        } else {
          ElMessage.error('提交失败，请联系管理员')
        }
      }, {
        message: this.workmes,
        phdepart: phdepart,
        timend: this.endtime,
        mainpeo: this.mainpeosel,
        sendpeo: '',
        nextweek: this.relweeknum,
        adduser: this.GET_USER,
        addpepart: this.GET_DEPART,
        userid: this.GET_USERID,
        isend: 0,
        audit: 0,
        bdstid: 1,
        beizhu: this.beizhu,
        flag: this.GET_DEPARTFLAG * 1
      })
    },
    editplan(row){
      this.editid = row.id;
      this.editmes = row.audit;
      console.log(this.editmes)
      this.axios.post("/userinfo/getleader", (response) => {
        this.mainpeos = response.obj
      }, {
        depart: this.GET_DEPART
      });
      this.dialogVisible1 = true;
      this.axios.get("/weekplan/getplanForEdit", (response) => {
        console.log(response.obj)
        this.workmes = response.obj.message;
        this.mainpeosel = response.obj.mainpeo;
        this.phpeoedit = response.obj.phdepart;
        this.endtime = response.obj.timend;
        this.beizhu = response.obj.beizhu
      },{
        id: row.id
      })
    },
    editworkplan(){
      this.axios.post("/weekplan/editplan", (response) => {
        if (response.obj){
          this.gethisweek();
          this.dialogVisible1 = false;
          ElMessage({
            message: '修改成功',
            type: 'success',
          });
        }
      },{
        message: this.workmes,
        phdepart: this.phpeoedit,
        mainpeo: this.mainpeosel,
        endtime: this.endtime,
        id: this.editid,
        beizhu: this.beizhu
      })
    }
  }
}
</script>

<style scoped>
.font1 {
  font-size: 16px;
  font-weight: 550;
  color: #000000;
  margin-top: 30px;
}

.font2 {
  font-size: 10px;
  font-weight: 400;
  color: #9D9D9D;
  margin-bottom: 30px;
}

.xzdiv {
  width: 200px;
  margin-right: 100px;
  text-align: center;
  box-shadow: 5px -2px 6px rgba(0, 0, 0, 0.1);
}
</style>
